import axios from 'axios'

const instance = axios.create({    
    baseURL : 'https://api.nccoss.org/',
})

/* login - 로그인 */
    /**
     * 로그인 하기
     * @param {*} params
     */
    function LoginAPI(data) {
        return instance.post('/login', data)
    }

    /**
     * 로그인 하기 - 첫번째 로그인인지 체크하기. -> 개인정보 동의 받기 위함
     * @param {*} params
     */
    function checkFirstLoginAPI(data) {
        return instance.post('/firstlogin', data)
    }

    /**
     * 로그인 하기 - 대학교 리스트 가져오기 -> 셀렉트 박스
     * @param {*} params
     */
    function getUniversityListAPI() {
        return instance.get('/univlist')
    }

    /**
     * 마이페이지 정보 불러오기
     * @param {*} params
     */
    function MypageAPI(loginId, socpsCd) {
        return instance.get('/mypage?loginId='+loginId+'&socpsCd='+socpsCd)
    }
    /**
     * 마이페이지 영어 이름 저장
     * @param {*} params
     */
    function updateStudentEnglishNameAPI(data) {
        return instance.post('/mypage/update', data)
    }

    /**
     * 시스템 접근 이력 로그 - LIST
     * @param {*} params
     */
    function getSystemLogListAPI() {
        return instance.get('/systemLog')
    }

    /**
     * 시스템 접근 이력 로그 - Search 조건 검색
     * @param {*} params
     */
    function getSystemLogSearchAPI(data) {
        return instance.post('/systemLog/getSystemLogSearch', data)
    }

    /**
     * 로그인 통계 로그 - LIST
     * @param {*} params
     */
    function getLoginStatisticsListAPI() {
        return instance.get('/loginStatistics')
    }

    /**
     * 로그인 통계 로그 - Search 조건 검색
     * @param {*} params
     */
    function getLoginStatisticSearchAPI(data) {
        return instance.post('/loginStatisticsearch', data)
    }

/* Notice */
    /**
     * 게시글 목록 조회
     * @param {*} params
     */
    function getBoardListAPI() {
        return instance.get('/notice')
    }
    /**
     * 게시글 조건 검색 조회
     * @param {*} params
     */
    function getBoardSearchAPI(data) {
        return instance.post('/notice/getNoticeSearch', data)
    }
    /**
     * 게시글 상세페이지 조회
     * @param {*} params
     */
    function getBoardDetailAPI(noticeId) {
        return instance.get('/notice/' + noticeId)
    }
    /**
     * 게시글 삭제 하기
     * @param {*} params
     */
    function BoardDeleteAPI(noticeId) {
        return instance.post('/notice/'+noticeId+'/delete')
    }
    /**
     * 게시글 등록 하기
     * @param {*} params
     */
    function BoardCreateAPI(data) {
        return instance.post('/notice/create', data)
    }

    /**
     * 게시글 수정 하기
     * @param {*} params
     */
    function BoardUpdateAPI(noticeId, data) {
        return instance.post('/notice/'+noticeId+'/update', data)
    }

/* END Notice */

/* QNA */

    /**
     * QNA 목록 조회
     * @param {*} params
     */
    function getQnaListAPI() {
        return instance.get('/qna')
    }

    /**
     * QNA 상세페이지 조회
     * @param {*} params
     */
    function getQnaDetailAPI(qnaId) {
        return instance.get('/qna/' + qnaId)
    }

    /**
     * QNA 조건 검색 조회
     * @param {*} params
     */
    function getQnaSearchAPI(data) {
        return instance.post('/qna/getQnaSearch', data)
    }

    /**
     * QNA 생성 하기
     * @param {*} params
     */
    function QnaCreateAPI(data) {
        return instance.post('/qna/create', data)
    }

    /**
     * QNA 수정 하기
     * @param {*} params
     */
    function QnaUpdateAPI(qnaId, data) {
        return instance.post('/qna/'+qnaId+'/update', data)
    }

    /**
     * QNA 삭제 하기
     * @param {*} params
     */
    function QnaDeleteAPI(qnaId) {
        return instance.post('/qna/'+qnaId+'/delete')
    }

    /**
     * QnaAnswer 상세페이지 조회 - Qna 답변
     * @param {*} params
     */
    function getQnaAnswerDetailAPI(qnaId) {
        return instance.get('/qna/' + qnaId + '/getQnaAnswer')
    }

    /**
     * QnaAnswer 등록 하기 - Qna 답변
     * @param {*} params
     */
    function QnaAnswerCreateAPI(qnaId, data) {
        return instance.post('/qna/'+qnaId+'/createQnaAnswer', data)
    }

    /**
     * QnaAnswer 수정 하기 - Qna 답변
     * @param {*} params
     */
    function QnaAnswerUpdateAPI(qnaId, data) {
        return instance.post('/qna/'+qnaId+'/updateQnaAnswer', data)
    }

    /**
     * QnaAnswer 삭제 하기 - Qna 답변
     * @param {*} params
     */
    function QnaAnswerDeleteAPI(qnaId) {
        return instance.post('/qna/'+qnaId+'/deleteQnaAnswer')
    }
/* END QNA */

/* DataRoom (자료실) */

    /**
     * DataRoom 목록 조회
     * @param {*} params
     */
    function getDataRoomListAPI() {
        return instance.get('/archives')
    }

    /**
     * DataRoom 조건 검색 조회
     * @param {*} params
     */
    function getDataRoomSearchAPI(data) {
        return instance.post('/archives/getArchivesSearch', data)
    }

    /**
     * DataRoom 상세페이지 조회
     * @param {*} params
     */
    function getDataRoomDetailAPI(archivesId) {
        return instance.get('/archives/' + archivesId)
    }

    /**
     * DataRoom 등록 하기
     * @param {*} params
     */
    function DataRoomCreateAPI(data) {
        return instance.post('/archives/create', data)
    }

    /**
     * DataRoom 수정 하기
     * @param {*} params
     */
    function DataRoomUpdateAPI(archivesId, data) {
        return instance.post('/archives/'+archivesId+'/update', data)
    }

    /**
     * DataRoom 삭제 하기
     * @param {*} params
     */
    function DataRoomDeleteAPI(archivesId) {
        return instance.post('/archives/'+archivesId+'/delete')
    }

/* END DataRoom (자료실) */

/* lecture (강의 목록) */

    /**
     * lecture 목록 조회
     * @param {*} params
     */
    function getLectureListAPI(userId, socpsCd) {
        return instance.get('/lecture',{
            params: {
                loginId: userId,
                socpsCd: socpsCd
            }
        });
    }

    /**
     * lecture 삭제 하기
     * @param {*} params
     */
    function LectureDeleteAPI(qnaId) {
        return instance.post('/lecture/'+qnaId+'/delete')
    }

    /**
     * lecture 조건 검색 조회
     * @param {*} params
     */
    function getLectureSearchAPI(data) {
        return instance.post('/lecture/getLectureSearch', data)
    }

/* END lecture (강의 목록) */

/* 학생 - myclass (내 강의 목록) */

    /**
     * 수강 신청 목록 조회
     * @param {*} params
     */
    function getEnrollListAPI() {
        return instance.get('/enroll')
    }

    /**
     * 수강 신청 목록 조회 - 페이징 만
     * @param {*} params
     */
    function getEnrollPageListAPI(pageIndex, pageSize) {
        return instance.get('/enroll?pageIndex='+pageIndex+'&pageSize='+pageSize)
    }

    /**
     * myclass 목록 조회
     * @param {*} params
     */
    function getMyClassListAPI(loginId) {
        return instance.get('/student/enrollList?loginId='+loginId)
    }

    /**
     * myclass 조건 검색 조회
     * @param {*} params
     */
    function getStudentLectureSearchAPI(loginId, data) {
        return instance.post('/student/getStudentLectureSearch?loginId='+loginId, data)
    }

    /**
     * myclass 상세 검색 조회
     * @param {*} params
     */
    function getStudentLectureDetailAPI(lectureId, loginId) {
        return instance.get('/student/'+lectureId+'?loginId='+loginId)
    }

    /**
     * myclass 강의 취소
     * @param {*} params
     */
    function StudentLectureDeleteAPI(courseId) {
        return instance.post('/student/'+courseId+'/delete')
    }

        /**
     * myclass 강의 취소, lectureId, userId
     * @param {*} params
     */
        function StudentLectureDeleteInLectureDetailAPI(lectureId, userId) {
            return instance.post('/student/'+lectureId+'/'+ userId +'/delete')
        }

    /**
     * myclass 강의 80% 이상 시 배지 발급
     * @param {*} params
     */
    function CreateBadgeAPI(data) {
        return instance.post('/createBadge', data)
    }

    /**
     * myclass 강의 시간 업데이트
     * @param {*} params
     */
    function updatePlayerTimeAPI(lectureWeeklyId, loginId, playTime) {
        return instance.post('/student/savePlayTime?lectureWeeklyId='+parseInt(lectureWeeklyId)+'&loginId='+loginId+'&playTime='+playTime)
    }

    /**
     * 강의 공지사항 - 목록 가져오기
     * @param {*} params
     */
    function getAllLectureNoticeAPI(lectureId) {
        return instance.get('/getAllLectureNotice/'+lectureId)
    }

    /**
     * 강의 공지사항 - 목록 조건검색
     * @param {*} params
     */
    function getLectureNoticeSearchAPI(lectureId, data) {
        return instance.post('/getLectureNoticeSearch/'+lectureId, data)
    }

    /**
     * 강의 공지사항 - 상세페이지
     * @param {*} params
     */
    function getLectureNoticeDetailAPI(lectureId, data) {
        return instance.post('/getLectureNoticeSearch/'+lectureId, data)
    }

/* END lecture (강의 목록) */

/* 교수 - course / subject (강의관리 - 강의 조회) */

/**
 * myclass 목록 조회
 * @param {*} params
 */
function getSubjectListAPI(lectureId) {
    return instance.get('/lecture/'+lectureId)
}

/**
 * subject 생성 하기
 * @param {*} params
 */
function SubjectCreateAPI(data) {
    return instance.post('/lecture/create', data)
}

/**
 * subject 수정 하기
 * @param {*} params
 */
function SubjectUpdateAPI(lectureId, data) {
    return instance.post('/lecture/'+lectureId+'/update', data)
}

/* END lecture (강의 목록) */

/* 학생 - badge (디지털 배지 목록) */

/**
 * 학생 - badge 목록 조회
 * @param {*} params
 */
function getBadgeStudentListAPI(userId) {
    return instance.get('/badge/'+userId)
}

/**
 * 학생 - badge 조건 검색 - 페이징 포함
 * @param {*} params
 */
function getBadgeStudentSearchAPI(userId, pageIndex, pageSize, lectureName, badgeCode) {
    return instance.get('/badge/'+userId+'?pageIndex='+pageIndex+'&pageSize='+pageSize+'&lectureName='+lectureName+'&badgeCode='+badgeCode)
}

/* END 학생 - badge (디지털 배지 목록) */

    /**
     * 공통 - badge 상세 검색
     * @param {*} params
     */
    function getBadgeDetailAPI(userId, badgeId) {
        return instance.get('/badge/'+userId+'/'+badgeId)
}

/* 교수 - badge (디지털 배지 목록) */

/**
 * 교수 - badge 목록 조회
 * @param {*} params
 */
function getBadgeListAPI() {
    return instance.get('/badge')
}

/**
 * 교수 - badge 조건 검색 - 페이징 포함
 * @param {*} params
 */
function getBadgeSearchAPI(data) {
    return instance.post('/badge/getBadgeSearch', data)
}

/* END 교수 - badge (디지털 배지 목록) */




/* 디지털 배지 임시페이지  2024.06.27 [Kpanda API] 임시 추가*/

/* 수강과목 배지 신청전 조회화면  임시 화면   수강과목 조회 */
function getAllIssuanceDtosAPI(){
    return instance.get('/issuance/rols');
}
function getIssuanceListByStudentIdAPI(params) {
    return instance.get('/issuance/portalId', { params });
}

// 업데이트된 getIssuanceSearchAPI 함수
function getIssuanceSearchAPI(params) {
    return instance.post('/issuance/getIssuanceSearch', params);
}

function applyBadgeAPI(data) {
    return instance.post('/issuance/apply', data);
}



/**
 * 수강과목 조건 검색 조회
 * @param {*} params
 */
// function getIssuanceSearchAPI(data) {
//     return instance.post('/issuance/getIssuanceSearch', data)
// }



/* 공통 - 파일 업로드 | 삭제 */

function uploadFileAPI(data) {
    return instance.post('/api/upload', data, {
        headers: {
            'Content-Type':'multipart/form-data'
        }
    })
}
function uploadImageAPI(data) {
    return instance.post('/api/uploadImage', data)
}
function deleteImageAPI(data) {
    return instance.post('/api/deleteImage', data)
}

function deleteFileAPI(data) {
    return instance.get('/api/delete/' + data)
}

function uploadExcelAPI(data) {
    return instance.post('/lecture/weekly/insertExcel', data, {
        headers: {
            'Content-Type':'multipart/form-data'
        }
    })
}

function uploadVideoAPI(data) {
    return instance.post('/api/uploadVideo', data, {
        headers: {
            'Content-Type':'multipart/form-data'
        }
    })
}

function saveVideoLengthAPI(data) {
    return instance.post('/api/saveVideoLength', data, {
        headers: {
            'Content-Type':'multipart/form-data'
        }
    })
}

function getBadgeVerificationAPI(data) {
    return instance.post('/badge/verification', data)
}

/* END 공통 - 파일 업로드 | 삭제 */

/* 주차별 저장 */

function getLectureWeeklyUpdateAPI(lectureId, data) {
    return instance.post('/lecture/'+lectureId+'/weekly/update', data)
}

/* END 주차별 저장 */

/* 담당교수, 마이크로디그리 불러오기 */

function getAllProfessorsAPI() {
    return instance.get('/lecture/getAllProfessors')
}

function createCourseAPI(data) {
    return instance.post('/enroll/create', data)
}

function courseCheckEnableAPI(data) {
    return instance.post('/course/checkEnable', data)
}

// function deleteLectureWeeklyAPI(lectureId, fileId, data) {
function deleteLectureWeeklyAPI(lectureId, data) {
    return instance.post('/lecture/'+lectureId+'/weekly/delete', data )
}

/*  */
function deleteListLectureAPI(lectureId, data) {
    return instance.post('/lecture/'+lectureId+'/delete', data )
}


/* END 담당교수, 마이크로디그리 불러오기 */


/* 성과지표 Start */

/**
 * 성과지표 - List
 * @param {*} params
 */
function getAllPerformanceAPI(univ) {
    return instance.get(`/performance/${univ}`)
}
// function getAllPerformanceAPI() {
//     return instance.get('/performance/kmu')
// }


/**
 * 성과지표 서울시립대학교 - List
 * @param {*} params
 */
function getUosPerformanceAPI() {
    return instance.get('/performance/uos')
}

/**
 * 성과지표 전남대학교 - List
 * @param {*} params
 */
function getCnuPerformanceAPI() {
    return instance.get('/performance/cnu')
}

/**
 * 성과지표 울산과학대학교 - List
 * @param {*} params
 */
function getUcPerformanceAPI() {
    return instance.get('/performance/uc')
}

/**
 * 성과지표 한국항공대학교 - List
 * @param {*} params
 */
function getKauPerformanceAPI() {
    return instance.get('/performance/kau')
}

/**
 * 성과지표 - List - 조건검색
 * @param {*} params
 */
// function getPerformanceSearchAPI(data) {
//     return instance.post('/performance/getPerformanceSearch', data)
// }

/**
 * 성과지표 - 지표구분 / 지표명 / 세부지표명 - 실시간으로 가져오기
 * @param {*} params
 */
// function getPerformanceListByIndicatorsAPI(type, indicatorId, subIndicatorName) {
//     let param = instance.get('/performance/getByIndicators')    

//     if (
//         type != null && type != undefined && type != ''
//             && indicatorId != null && indicatorId != undefined && indicatorId != ''
//                 && subIndicatorName != null && subIndicatorName != undefined && subIndicatorName != ''
//     ) {
//         param = instance.get('/performance/getByIndicators?type='+type+'&indicatorId='+indicatorId+'&subIndicatorName='+subIndicatorName)
//     } else if (
//         type != null && type != undefined && type != ''
//         && indicatorId != null && indicatorId != undefined && indicatorId != ''
//     ) {
//         param = instance.get('/performance/getByIndicators?type='+type+'&indicatorId='+indicatorId)
//     } else if (
//         type != null && type != undefined && type != ''
//     ) {
//         param = instance.get('/performance/getByIndicators?type='+type)
//     } else {
//         param = instance.get('/performance/getByIndicators')
//     }
//     return param
// }

/**
 * 성과지표 - Detail 상세 페이지 - 데이터 있는지 가져오기
 * @param {*} params
 */
function getPerformanceListAPI() {
    return instance.get('/performance/getPerformanceList')
}

// 성과지표 - Detail 상세 페이지
function getPerformanceByIdAPI(performanceId) {
    return instance.get(`/performance/kmu/${performanceId}`);
  }
function getUosPerformanceById(performanceId) {
return instance.get(`/performance/uos/${performanceId}`);
}
function getCnuPerformanceById(performanceId) {
return instance.get(`/performance/cnu/${performanceId}`);
}
function getUcPerformanceById(performanceId) {
return instance.get(`/performance/uc/${performanceId}`);
}
function getKauPerformanceById(performanceId) {
return instance.get(`/performance/kau/${performanceId}`);
}

/**
 * 성과지표 연간목표값(yearly goal) 수정
 * @param {*} params
 */
function updateYearGoalAPI(value, perfId, data){
    return instance.post(`/performance/${value}/${perfId}/updateYearly`, data)
}

// 아래 통합 API에서 쓰는 매개변수 
// value => 각 테이블명 (예) advEduDev

/** 
 * 성과지표 테이블별 조회 통합API > allSelect
 * @param {*} params
 */
function getAllPerfDetailAPI(data) {
    return instance.post(`/performance/${data.se}`, data)
}
// function getAllPerfDetailAPI(data) {
//     return instance.get(`/performance/${data.se}`, {
//         params : {
//             se: data.se,
//             perfId: data.perfId,
//             univ: data.univ,
//             orderType: data.orderType,
//             orderKey: data.orderKey
//         }
//     })
// }
// function getAllPerfDetailAPI(value, perfId, univName) {
//     return instance.get(`/performance/${value}?perfId=${perfId}&se=${value}&univ=${univName}`)
// }

/**
 * 성과지표 테이블별 조회 통합API > getById
 * @param {*} params
 */
function getPerfDataByIdAPI(value, perfId){
    return instance.get(`/performance/${value}/${perfId}?se=${value}`)
}

/**
 * 성과지표 테이블별 insert 통합API > create테이블명
 * @param {*} params
 */
function createPerfDataByTablesAPI(value, data) {
    return instance.post(`/performance/${value}/create`, data)
}

/**
 * 성과지표 테이블별 update 통합API > update테이블명
 * @param {*} params
 */
function updatePerfDataByTablesAPI(value, perfId, data) {
    return instance.post(`/performance/${value}/${perfId}/update`, data)
}

/**
 * 성과지표 테이블별 delete 통합API > delete테이블명
 * @param {*} params
 */
function deletePerfDetailAPI(value, seqc){
    return instance.post(`/performance/${value}/${seqc}/delete`)
}

/**
 * 성과지표 핵심 테이블 목표와 성과, total 가져오기
 * @param {*} params
 */
// sep -> core, self
function getEntirePerformanceAPI(sep){
    return instance.get(`/performance/entire/${sep}`)
}

/**
 * 성과지표 전체 참여대학 - detail
 * @param {*} params
 */
function getEntirePerformanceByIdAPI(perfId){
    return instance.get(`/performance/entire/detail/${perfId}`)
}

/**
 * 성과지표 자율+기타 테이블 목표와 성과, total 가져오기
 * @param {*} params
 */
function getEntireSelfPerformanceAPI(sep){
    return instance.get(`/performance/entire/${sep}`)
}

/**
 * 성과지표 KMOOC 개발/운영 페이지에서 받아올 강좌목록 조회 API
 * @param {*} params
 */
function getAllKmoocSubSearchAPI(data){
    return instance.post(`/performance/kmoocSub/search`, data)
}

/**
 * 성과지표 - create
 * @param {*} params
 */
function createPerformanceAPI(data) {
    return instance.post('/performance/create', data)
}

/**
 * 성과지표 - update
 * @param {*} params
 */
function updatePerformanceAPI(performanceId, data) {
    return instance.post('/performance/update/' + performanceId, data)
}

/**
 * Main - 성과지표 - chart
 * @param {*} params
 */
function mainPerformanceChartAPI() {    
    return instance.get('/performance/getPerformanceTotal');
}
/* 성과지표 END */

/* 회원관리 Start */

/**
 * 회원관리 - LIST
 * @param {*} params
 */
function getUserListAPI() {
    return instance.get('/auth')
}

/**
 * 회원관리 - UPDATE
 * @param {*} params
 */
function updateManageAdminAPI(userId, admin) {
    return instance.get('/auth/manage?userId='+userId+'&admin='+admin)
}

/**
 * 회원관리 - 조건검색
 * @param {*} params
 */
function getAuthSearchAPI(data) {
    return instance.post('/auth/getAuthSearch', data)
}

/**
 * 회원관리 - 실시간 권한 체크
 * @param {*} params
 */
function getAuthAPI(userId) {
    return instance.get('/getAuth?userId='+userId)
}

/**  
 * 출결관리 - 24/04/11 다운 추가
 * @param {*} params
*/
function getLecutreAttendanceListAPI(loginId, socpsCd){
    return instance.get('/attendance?loginId='+loginId+'&socpsCd=' + socpsCd)
}

/** 
 * 출결관리 - 조건검색 
 * @param {*} params
 * */
function getAttendanceLectureSearchAPI(loginId, socpsCd, data){
    return instance.post('/attendance/getLectureSearch?loginId='+loginId+'&socpsCd='+socpsCd, data)
}
/** 
 * 출결관리 - 상세보기
 * @param {*} params
 */
function getAttendanceListDetailByIdAPI(lectureId, loginId, socpsCd, weekOrder){
    if(weekOrder == null || weekOrder == ''){
        return instance.get(`/attendance/${lectureId}?loginId=`+loginId+'&socpsCd='+socpsCd)
    }
    else{
        return instance.get(`/attendance/${lectureId}?loginId=`+loginId+'&socpsCd='+socpsCd+'&weekOrder='+weekOrder)
    }
}

/** 
 * 출결관리 - 데이터 가져오기
 * @param {*} params
 */

/** 
 * 출결관리 - 출결 버튼 클릭시 데이터 백엔드로 전송
 * @param {*} params
 */
function updateAttendanceAPI(lectureId, lectureDetailId, attendanceList) {
    // attendanceData 객체를 생성하여 API 요청 본문으로 사용
    const attendanceData = {
        attendanceList
    };

    return instance.post(`/attendance/${lectureId}/${lectureDetailId}/updateAttendance`, attendanceData);
}

/**  
 * 평가관리 - 목록보기 24/04/12 다운 추가
 * @param {*} params
*/
function getAllEvaluationListAPI(loginId, socpsCd){
    return instance.get('/evaluation?loginId='+loginId+'&socpsCd='+socpsCd)
}

/** 
 * 평가관리 - 검색
 * @param {*} params
*/
function getEvaluationSearchAPI(loginId, pageIndex, pageSize, data){
    return instance.post('/evaluation/getEvaluationSearch?loginId='+loginId+"&pageIndex="+pageIndex+"&pageSize="+pageSize, data)
}

/**
 * 평가관리 - 상세보기
 * @param {*} params
 */
function getEvaluationByIdAPI(lectureId){
    return instance.get("/evaluation/"+lectureId)
}

/**
 * 평가관리 - 등록 및 수정
 * @param {*} params
 */
function updateLectureEvalDetailAPI(lectureId, data){
    return instance.post("/evaluation/"+lectureId+"/updateEval", data)
}

/**
 * 평가관리 - 시험기간 관리
 * @param {*} params
 */
function getExamInfoAPI(lectureId){
    return instance.get("/evaluation/"+lectureId+"/getExamInfo")
}

/**
 * 평가관리 - 시험기간 수정
 * @param {*} params
 */
function updateExamInfoAPI(lectureId, data){
    return instance.post("/evaluation/"+lectureId+"/updateExamInfo", data)
}

/**
 * 성적관리 - 성적정보, 성적입력 조회
 * @param {*} params
 */
function getGradeListByIdAPI(lectureId, pageIndex, pageSize){
    return instance.get("/grade/" + lectureId, {
        params: {
            pageSize: pageSize,
            pageIndex: pageIndex
        }
    });
}

/**
 * 강의 - 내 성적
 * @param {*} params
 */
function getStudentGradeAPI(userId){
    return instance.get("/studentGrade", {
        params: {
            loginId : userId
        }
    });
}

/**
 * 강의 - 내 성적 검색
 * @param {*} params
 */
function getStudentGradeSearchAPI(userId, additionalData) {
    const data = {
        userId: userId,  // 이전에는 loginId라는 이름을 사용했으나 DTO에 맞춰 userId로 변경
        ...additionalData  // 추가적으로 필요한 다른 데이터 필드
    };
    return instance.post("/studentGrade/getStudentGradeSearch", data);
}
/**
 * 강의 - 내 성적 검색
 * @param {*} params
 */
function getLectureOppositionListAPI(lectureId, data) {
    return instance.post("/opposition/"+lectureId, data);
}

/**
* 성적관리 - 이의신청
* @param {*} params
*/
function getOppositionByIdAPI(scoreId) {
    return instance.get('/opposition/getOpposition/'+scoreId)
}

/**
* 성적관리 - 이의신청
* @param {*} params
*/
function createPrfsrOppositionAPI(data) {
    return instance.post('/opposition/createPrfsrOpposition', data)
}
/**
* 내 성적 - 이의신청 강의 정보 조회
* @param {*} params
*/
function getLectureInfoByCourseIdAPI(courseId) {
    return instance.get('/opposition/getLectureInfo/'+courseId)
}

/**
* 내 성적 - 이의신청 강의 정보 조회
* @param {*} params
*/
function createStdntOppositionAPI(data) {
    return instance.post('/opposition/createStdntOpposition', data)
}
/**
* 내 성적 - 이의신청 신청 내용 조회
* @param {*} params
*/
function getOppositionByScoreId(scoreId) {
    return instance.get('/opposition/'+scoreId+'/getOppositionByScoreId')
}
/**
* 내 성적 - 내 성적 조회
* @param {*} params
*/
function getStudentGradeByIdAPI(lectureId, loginId) {
    return instance.get('/studentGrade/'+lectureId+'?loginId='+ loginId);
}
/**
 * 성적관리 - 성적정보, 성적입력 조회
 * @param {*} params
 */
function saveGradeAPI(lectureId, data){
    return instance.post("/grade/" + lectureId + "/saveGrade", data);
}


/**
 * 시험 - 시험 응시 - 시험목록
 * @param {*} params
 */
function getStudentExamListAPI(userId){
    return instance.get('/studentExam?userId=' + userId)
}
//test-F200017
/**
 * 시험 - 시험 응시 - 응시 페이지
 * @param {*} params
 */
function takeStudentExamAPI(lectureId, examId, userId){
    return instance.get(`/studentExam/${lectureId}/${examId}/${userId}`)
}

/**
 * 시험 - 시험 응시 - 답안 제출
 * @param {*} params
 */
function saveStudentExamAnswerAPI(lectureId, examId, userId, data){
    return instance.post(`/studentExam/${lectureId}/${examId}/${userId}/saveAnswers`, data)
}

/**
 * 시험 - 결과 확인 목록
 * @param {*} params
 */
function getStudentExamResultListAPI(userId){
    return instance.get(`/studentExam/${userId}/getExamResult`)
}

/**
 * 시험 - 결과 확인 상세
 * @param {*} params
 */
function getStudentExamResultDetailAPI(userId, examId, scoreId){
    return instance.get(`/studentExam/${userId}/${examId}/${scoreId}/getExamResultDetail`)
}

/**
 * 강의관리 - 문제은행
 * @param {*} params
 */
function getAllExamDtosAPI(loginId) {
    return instance.get('/exam?loginId='+ loginId);
}
/**
 * 강의관리 - 문제은행 검색
 * @param {*} params
 */
function getExamSearchAPI(loginId, data) {
    return instance.post('/exam/getExamSearch?loginId='+ loginId, data);
}
/**
 * 강의관리 - 문제은행 상세 리스트
 * @param {*} params
 */
function getExamQuestionListByLectureIdAPI(lectureId) {
    return instance.get('/exam/' + lectureId);
}
/**
 * 강의관리 - 문제은행 상세 리스트 검색
 * @param {*} params
 */
function getExamQuestionListByLectureIdSearchAPI(lectureId, data) {
    return instance.post('/exam/' + lectureId+'/getExamSearch',data)
}
/**
 * 강의관리 - 문제은행 상세 리스트 삭제
 * @param {*} params
 */
function deleteExamQuestionAPI(lectureId, data) {
    return instance.post('/exam/' + lectureId+'/deleteExamQuestion',data)
}

/**
 * 강의관리 - 문제은행 문항 상세 페이지 (240503 다운추가)
 * @param {*} params
 */
function getExamQuestionDetailAPI(lectureId, examQuestionId){
    return instance.post(`/exam/${lectureId}/${examQuestionId}`)
}

/**
 * 강의관리 - 문제은행 문항 등록 (240503 다운추가)
 * @param {*} params
 */
function saveExamQuestionAPI(lectureId, data){
    return instance.post(`/exam/${lectureId}/saveExamQuestion`, data)
}

/**
 * 시험관리 - 교수 시험채점 목록 (240507 다운추가)
 * @param {*} params
 */
// function getGradingLectureDtosAPI(userId, socpsCd, params){
//     return instance.get('/grading?userId='+userId+'&'+'socpsCd='+socpsCd, {
//         params: {
//             "pageIndex" : params.pageIndex,
//             "pageSize": params.pageSize
//         }
//     })
// }
function getGradingLectureDtosAPI(userId, socpsCd, params) {
    const queryParams = {
        userId,
        socpsCd
    }
    if (params) {
        if (params.pageIndex !== undefined) queryParams.pageIndex = params.pageIndex;
        if (params.pageSize !== undefined) queryParams.pageSize = params.pageSize;
    }
    return instance.get('/grading', {
        params: queryParams
    })
}


/**
 * 시험관리 - 교수 채점할 학생목록 (240507 다운추가)
 * @param {*} params
 */
function getGradingStudentListAPI(lectureId, examId){
    return instance.get(`/grading/${lectureId}/${examId}`)
}

/**
 * 시험관리 - 채점 상세 (240507 다운추가)
 * @param {*} params
 */
function getStudentAnswerByIdAPI(lectureId, examId, userId, params){
    return instance.get(`/grading/${lectureId}/${examId}/${userId}`, {
        params: params
    })
}

/**
 * 시험관리 - 수동 채점 (240507 다운추가)
 * @param {*} params
 */
function saveGradingManuallyAPI(lectureId, examId, userId, data){
    return instance.post(`/grading/${lectureId}/${examId}/${userId}/saveGrading`, data)
}

/**
 * 시험관리 - 수동 채점 후 학생 토탈점수 및 등급 재계산 API (240513 다운추가)
 * @param {*} params
 */
function updateScoreForManuallyAPI(data){
    return instance.post('/grade/updateScoreForManually', data)
}

// 파일 다운로드 함수
function downloadFileAPI(alterFileName, originalFileName) {
    return instance.get(`/api/${encodeURIComponent(alterFileName)}/download`, {
    // return instance.get(`/api/${alterFileName}/download`, {
        responseType: 'blob', // 파일 데이터를 Blob 형태로 받음
    }).then((response) => {
        // Blob 객체를 생성하고 URL을 만듭니다.
        const url = window.URL.createObjectURL(new Blob([response.data]));
        // a 태그를 생성하여 프로그래매틱하게 클릭 이벤트를 발생시켜 다운로드를 진행합니다.
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', originalFileName); // 다운로드할 파일명을 지정합니다.
        document.body.appendChild(link);
        link.click();
        // 링크를 제거하고 생성된 URL을 해제합니다.
        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);
    }).catch((error) => {
        console.error('File download failed:', error);
    });
}

// @@ 사업비관리

// 모든 데이터 가져오기 

function getAllProjectFundingApi() {
    return instance.get(`/projectfunding/all`);
}

// 사업비 가져오기 (대학, 연도)
function getProjectFundingApi(univ, year) {
    return instance.get(`/projectfunding/${univ}/${year}`);
}

// 사업비 등록, 수정 
function upsertProjectFundingApi(univ, year, data) {
    return instance.post(`/projectfunding/${univ}/${year}/upsert`, data);
}




/* 회원관리 END */
export {
    BoardCreateAPI, BoardDeleteAPI, BoardUpdateAPI, CreateBadgeAPI, DataRoomCreateAPI, DataRoomDeleteAPI, DataRoomUpdateAPI, LectureDeleteAPI,
    /* 로그인 API / 마이페이지 / 시스템 로그 이력 / 로그인 통계 이력 */
    LoginAPI, MypageAPI,
    /* QnaAnswer - 답변 */
    QnaAnswerCreateAPI, QnaAnswerDeleteAPI, QnaAnswerUpdateAPI, QnaCreateAPI, QnaDeleteAPI, QnaUpdateAPI, StudentLectureDeleteAPI,
    StudentLectureDeleteInLectureDetailAPI, SubjectCreateAPI,
    SubjectUpdateAPI, applyBadgeAPI, checkFirstLoginAPI, courseCheckEnableAPI, createCourseAPI,
    // getPerformanceListByIndicatorsAPI,
    createPerformanceAPI, createPrfsrOppositionAPI, createStdntOppositionAPI, deleteExamQuestionAPI, deleteFileAPI, deleteImageAPI, deleteLectureWeeklyAPI,
    deleteListLectureAPI, downloadFileAPI,
    /* 평가관리 */
    getAllEvaluationListAPI,
    /* 문제뱅크 */
    getAllExamDtosAPI,
    /* 임시 페이지 API */
    getAllIssuanceDtosAPI,
    /* 강의 공지사항 */
    getAllLectureNoticeAPI,
    /* 성과지표 */
    getAllPerformanceAPI, getAllProfessorsAPI, getAttendanceLectureSearchAPI,
    getAttendanceListDetailByIdAPI, getAuthAPI, getAuthSearchAPI,

    /* 성과지표 연간 목표값 수정 */
    updateYearGoalAPI,

    /* 테이블별 조회 api > 24.08.07 이후 추가 */
    getAllPerfDetailAPI,    // 테이블별 조회 통합 api
    getPerfDataByIdAPI,        // 테이블별 상세조회 통합 api
    createPerfDataByTablesAPI, // 테이블별 insert 통합 api 
    updatePerfDataByTablesAPI, // 테이블별 update 통합 api
    deletePerfDetailAPI,    // 테이블별 delete 통합 api 
    getEntirePerformanceAPI,
    getEntirePerformanceByIdAPI,
    // getEntireCorePerformanceAPI,
    getEntireSelfPerformanceAPI,

    // kmooc 개발/운영 에서 조회할 강좌목록 
    getAllKmoocSubSearchAPI,

    /* 공통 - 상세 검색 */
    getBadgeDetailAPI,

    /* 교수 - badge (디지털 배지 목록) */
    getBadgeListAPI,
    getBadgeSearchAPI,
    /* 학생 - badge (디지털 배지 목록) */
    getBadgeStudentListAPI,
    getBadgeStudentSearchAPI, getBadgeVerificationAPI, getBoardDetailAPI,
    /* 보드에 필요한 영역 존 입니다. */
    getBoardListAPI,
    getBoardSearchAPI, getCnuPerformanceAPI, getCnuPerformanceById, getDataRoomDetailAPI,
    /* DataRoom - 자료실 */
    getDataRoomListAPI,
    getDataRoomSearchAPI,
    /* 학생 - myclass (내 강의 목록) */
    getEnrollListAPI,
    getEnrollPageListAPI, getEvaluationByIdAPI, getEvaluationSearchAPI, getExamInfoAPI, getExamQuestionDetailAPI, getExamQuestionListByLectureIdAPI,
    getExamQuestionListByLectureIdSearchAPI, getExamSearchAPI,
    /* 성적관리 */
    getGradeListByIdAPI,
    /* 시험관리 */
    getGradingLectureDtosAPI,
    getGradingStudentListAPI, getIssuanceListByStudentIdAPI, getIssuanceSearchAPI,
    // getIssuanceListByStudentIdAPI,
    /**/
    getKauPerformanceAPI, getKauPerformanceById, getLectureInfoByCourseIdAPI,
    /* Lecture (강의 목록) */
    getLectureListAPI, getLectureNoticeDetailAPI, getLectureNoticeSearchAPI, getLectureOppositionListAPI, getLectureSearchAPI,
    /* 주차별 저장 */
    getLectureWeeklyUpdateAPI,
    /* 출결관리 */
    getLecutreAttendanceListAPI, getLoginStatisticSearchAPI, getLoginStatisticsListAPI, getMyClassListAPI, getOppositionByIdAPI, getOppositionByScoreId,
    // getPerformanceSearchAPI,
    getPerformanceByIdAPI, getPerformanceListAPI, getQnaAnswerDetailAPI, getQnaDetailAPI,
    /* Qna */
    getQnaListAPI, getQnaSearchAPI, getStudentAnswerByIdAPI,
    /* 시험 */
    getStudentExamListAPI, getStudentExamResultDetailAPI, getStudentExamResultListAPI, getStudentGradeAPI, getStudentGradeByIdAPI, getStudentGradeSearchAPI, getStudentLectureDetailAPI, getStudentLectureSearchAPI,
    /* 교수 - course / subject (강의관리 - 강의 조회) */
    getSubjectListAPI, getSystemLogListAPI,
    getSystemLogSearchAPI, getUcPerformanceAPI, getUcPerformanceById, getUniversityListAPI, getUosPerformanceAPI, getUosPerformanceById,
    /* 회원관리 */
    getUserListAPI, mainPerformanceChartAPI, saveExamQuestionAPI, saveGradeAPI, saveGradingManuallyAPI, saveStudentExamAnswerAPI, saveVideoLengthAPI, takeStudentExamAPI, updateAttendanceAPI, updateExamInfoAPI,
    // createLectureEvaluationAPI,
    updateLectureEvalDetailAPI, updateManageAdminAPI, updatePerformanceAPI, updatePlayerTimeAPI, updateScoreForManuallyAPI, updateStudentEnglishNameAPI, uploadExcelAPI,
    /* 공통 - 파일 업로드 | 삭제 */
    uploadFileAPI,
    uploadImageAPI, uploadVideoAPI,

    // 사업비관리
    getProjectFundingApi,
    upsertProjectFundingApi,
    getAllProjectFundingApi
}

