import { createStore } from "vuex";
import { getPerfDataByIdAPI, createPerfDataByTablesAPI, updatePerfDataByTablesAPI, getAllKmoocSubSearchAPI } from "../api";

export default createStore({
  state: {
    popups: [],
    data: false,
    gubun: "",
    // 성과관리용
    popupData: {},
    uriPath: "",
    uriId: "",
    perfGubun: "",
    // 성과관리 -> kmooc 관련 개발, 운영에서 가져올 강의목록
    subjectList: {},
    subTitle: "",
    subUniv: "",
  },
  mutations: {
    openPopup(state, { id, data, gubun }) {
      state.popups.push({ id, data, gubun });
    },
    closePopup(state, id) {
      state.popups = state.popups.filter((popup) => popup.id !== id);
    },
    getResult(state, data) {
      state.data = data.data;
      state.gubun = data.gubun;
    },
    setPopupData(state, data) {
      state.popupData = data;
    },
    setVariables(state, {uriPath, uriId, perfGubun}) {
      state.uriPath = uriPath;
      state.uriId = uriId;
      state.perfGubun = perfGubun;
    },
    setPerfGubun(state, data){
      state.perfGubun = data;
    },
    setSubjectList(state, data){
      state.subjectList = data;
    },
    setSubTitle(state, {subTitle, subUniv}){
      state.subTitle = subTitle;
      state.subUniv = subUniv;
    }
  },
  actions: {
    openPopup({ commit }, { id, data, gubun }) {
      commit("openPopup", { id, data, gubun });
    },
    closePopup({ commit }, id) {
      commit("closePopup", id);
    },
    getResult({ commit }, data) {
      commit("getResult", data);
    },
    async fetchPopupData({ commit }, {uriPath, uriId}) {
      try {
        let response = {};
        response = await getPerfDataByIdAPI(uriPath, uriId);

        if (response !== null) {
          const datas = response.data;
          commit("setPopupData", datas);
        }
      } catch (error) {
        console.error("fetchPopupData 함수 에러 => ", error);
      }
    },
    // LmsPopPerf에서 저장할 때 쓸 action
    async savePopupData({ state }, {uriPath, data}) {
      try {
        // insert 함수
        let response = await createPerfDataByTablesAPI(uriPath, data);
        return response
      } catch (error) {
        console.error(state, "savePopupData 함수 에러 => ", error);
      }
    },
    async updatePopupData({state} , {uriPath, uriId, data}) {
      try {
        let response = await updatePerfDataByTablesAPI(uriPath, uriId, data);
        return response
      } catch (error) {
        console.error("updatePopupData 에러", state, error)
      }
    },
    async fetchSubjectList({ commit }, {selectedValue, text}) {
    // async fetchSubjectList({ commit }, {data}) {
      // KMOOC 개발/운영 페이지에서 값을 가져올 API
      // selectedValue : 검색할 옵션 (ex) 대학명, ...)
      // text : 검색학 텍스트 (input에 입력한 거)
      try {
        let response = {};
        let requestData = { 
          selectedValue: selectedValue,
          text: text
        }
        response = await getAllKmoocSubSearchAPI(requestData);

        if(response !== null){
          console.log('fetchSubjectList >>> ',response)
          const datas = response.data.data;
          commit("setSubjectList", datas);
        }

      } catch (error) {
        console.error("fetchSubjectListData 에러", error)
      }
    }
  },
  getters: {
    getResult: (state) => {
      return { data: state.data, gubun: state.gubun };
    },
    getPopups: (state) => state.popups,
    getPopupById: (state) => (id) =>
      state.popups.find((popup) => popup.id === id),
    isAnyPopupOpen: (state) => {
      return state.popups.length > 0;
    },
    // LmsPopPerf에서 쓰는 getter
    getPopupData: (state) => {return state.popupData;},
    getVariables: (state) => {return {uriPath: state.uriPath, uriId: state.uriId, perfGubun: state.perfGubun};},
    getPerfGubun: (state) => { return state.perfGubun; },
    getSubjectList: (state) => {return state.subjectList;},
    getSubTitle: (state) => {return {subTitle: state.subTitle, subUniv: state.subUniv}}
  },
});
