<template>
  <div>
    <div id="header" :class="{ scrolled: scrolled, showBackground: showBackground }">
      <div class="row">
        <div class="logo-wrap">
          <div class="logo">
            <router-link to="/">
              <img src="@/assets/user/img/logo_wh.png" alt="logo">
            </router-link>
          </div><!--logo-->
        </div><!--logo-wrap-->

        <div class="menu_wrap">
          <div id="gnb">
            <ul class="row" >
              <li>
                <router-link to="/page/greeting">
                  사업단소개
                </router-link>
              </li>
              <li>
                <router-link to="/page/programs">
                  교육과정
                </router-link>
              </li>
              <li>
                <router-link to="/page/digital-badges">
                  디지털배지
                </router-link>
              </li>
              <li>
                <router-link to="/page/notice">
                  커뮤니티
                </router-link>
              </li>
            </ul>
            <div class="snb_area" ref="snbArea">
              <div class="snb_bg">
                <ul>
                  <li>
                    <router-link to="/page/greeting">
                      단장 인사말
                    </router-link>
                  </li>
                  <li>
                    <router-link to="/page/introduction">
                      사업 소개
                    </router-link>
                  </li>
                  <!-- <li>
                    <router-link to="/page/performance-indicator">
                      성과 지표
                    </router-link>
                  </li> -->
                </ul>

                <ul>

                  <li>
                    <router-link to="/page/programs">
                      교육과정 소개
                    </router-link>
                  </li>

                  <li>
                    <router-link to="/page/guide">
                      교과목 안내
                    </router-link>
                  </li>

                  <!-- <li>
                    <router-link to="/login">
                      수강신청
                    </router-link>
                  </li> -->

                </ul>

                <ul>
                  <li>
                    <router-link to="/page/digital-badges">
                      디지털배지 소개
                    </router-link>
                  </li>
                  <li>
                    <router-link to="/page/issuance-process">
                      발급절차
                    </router-link>
                  </li>
                  <li>
                    <router-link to="/page/verification">
                      진위여부 확인
                    </router-link>
                  </li>
                </ul>

                <ul>
                  <li>
                    <router-link to="/page/notice">
                      공지사항
                    </router-link>
                  </li>
                  <li>
                    <router-link to="/page/qna">
                      Q&A
                    </router-link>
                  </li>
                  <li>
                    <router-link to="/page/data-room">
                      자료실
                    </router-link>
                  </li>
                </ul>

                <ul></ul>
              </div><!--snb_bg-->
            </div><!--snb_area-->
          </div> <!--gnb-->
          <div class="bar"></div>
          <div class="login">
            <router-link to="/login">로그인</router-link>
          </div>
        </div><!--menu_wrap-->
      </div><!--row-->

      <div class="mobile-btn" @click="openMobileMenu">
        <span class="material-symbols-outlined">menu</span>
      </div><!--mobile-btn-->
    </div><!--#header-->

<!--    <div class="close-btn" @click="closeMobileMenu">-->
<!--      <input type="checkbox" id="check_box"/>-->
<!--      <label for="check_box" class="ham_menu">-->
<!--        <span class="bar"></span>-->
<!--        <span class="bar"></span>-->
<!--      </label>-->
<!--    </div>&lt;!&ndash;mobile-btn&ndash;&gt;-->


    <div class="side_menu_wrap" >
      <div class="side_menu">
        <div class="mobile-btn" @click="closeMobileMenu">
          <span class="material-symbols-outlined">close</span>
        </div><!--mobile-btn-->

        <div class="m-login">
          <router-link to="/login">로그인</router-link>
        </div>
        <div class="mobile_menu" @click="clickMenu('menu1')">
          사업단소개
        </div><!--mobile_menu-->
        <div class="mobile_sub_wrap" v-show="mobileMenuActive === 'menu1'" :class="{ 'active': mobileMenuActive === 'menu1' }">
          <router-link to="/page/greeting">
            <div>
              단장인사말
            </div>
          </router-link>

          <router-link to="/page/introduction">
            <div>사업소개</div>
          </router-link>

          <router-link to="/page/performance-indicator">
            <div>성과지표</div>
          </router-link>

        </div><!--mobile_sub_wrap-->

        <div class="mobile_menu" @click="clickMenu('menu2')">
          교육과정
        </div><!--mobile_menu-->

        <div class="mobile_sub_wrap" v-show="mobileMenuActive === 'menu2'">
          <router-link to="/page/programs">
            <div>교육과정 소개</div>
          </router-link>
          <router-link to="/page/guide">
            <div>교과목 안내</div>
          </router-link>
          <router-link to="/login">
            <div>수강신청</div>
          </router-link>
        </div><!--mobile_sub_wrap-->

        <div class="mobile_menu" @click="clickMenu('menu3')">
          디지털배지
        </div><!--mobile_menu-->
        <div class="mobile_sub_wrap" v-show="mobileMenuActive === 'menu3'">
          <router-link to="/page/digital-badges">
            <div>디지털배지 소개</div>
          </router-link>
          <router-link to="/page/issuance-process">
            <div>발급절차</div>
          </router-link>
          <router-link to="/page/verification">
            <div>진위확인</div>
          </router-link>
        </div><!--mobile_sub_wrap-->
        <div class="mobile_menu" @click="clickMenu('menu4')">
          커뮤니티
        </div><!--mobile_menu-->
        <div class="mobile_sub_wrap" v-show="mobileMenuActive === 'menu4'">

          <router-link to="/page/notice">
            <div>공지사항</div>
          </router-link>
          <router-link to="/page/qna">
            <div>Q&A</div>
          </router-link>
          <router-link to="/page/data-room">
            <div>자료실</div>
          </router-link>

        </div><!--mobile_sub_wrap-->
      </div><!--side_menu-->
    </div><!--side_menu_wrap-->
  </div>
</template>

<script>

export default {
  name: 'mainNav',
  components:[],
  props: {
    msg: String
  },
  data() {
    return {
      mobileMenuActive: null,
      scrollPosition: 0,
      scrolled: false,
      showBackground: false,
      showMobileMenu: false,
    };
  },
  computed: {
    shouldShowLogo() {
      const mainPagePath = '/';
      const currentRoute = this.$route.path;
      return currentRoute !== mainPagePath;
    },
  },
  mounted() {
    this.handleScroll();
    window.addEventListener('scroll', this.handleScroll);
    this.$router.afterEach(() => {
      // this.showMobileMenu = this.closeMobileMenu();
      // this.closeMobileMenu();
    });
  },
  beforeUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  },
  methods: {
    handleScroll() {
      const header = document.querySelector('header');
      if (header) {
        header.classList.toggle('scrolled', window.scrollY > 0);
      }
      this.scrollPosition = window.scrollY;
      this.showBackground = window.scrollY > 0;
    },
    clickMenu(menu) {
      if (this.mobileMenuActive === menu) {
        this.mobileMenuActive = null;
      } else {
        this.mobileMenuActive = menu;
      }
    },

    openMobileMenu() {
      const sideMenu = document.querySelector('.side_menu_wrap');
      document.documentElement.classList.add('disable');
      sideMenu.classList.add('show');
    },

    closeMobileMenu() {
      this.mobileMenuActive = null
      const sideMenu = document.querySelector('.side_menu_wrap');
      document.documentElement.classList.remove('disable');
      sideMenu.classList.remove('show');
    },

    toggleMobileMenu() {
      this.showMobileMenu = !this.showMobileMenu;
    }
  }
}
</script>



<style scoped>


body{
  width: 100%;
  height: 100%;
  background-color: black;
}
a, li {
  list-style: none;
  text-decoration: none;
  border: none;

}


#header {
  width: 100%;
  height: 100px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: rgba(7, 28, 48, 0.1);
  z-index: 2;
  box-sizing: border-box;
  top: 0;
  right: 0;
  left: 0;
  position: fixed;
  transition: all ease-in-out 0.2s;
}

#header.showBackground {
  background-color: rgba(7, 28, 48, 0.9);
}

#gnb {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

#gnb ul.row {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}

#gnb ul.row li{
  flex: 0 0 150px;
  width: 150px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 1.8rem;
  text-align: center;
  padding: 20px 0;
}

#header >.row {
  width: 100%;
  height: 100%;
  max-width: 1200px;
  display: flex;
  flex-direction: row;
  align-items: center !important;
  justify-content: space-around !important;
}

#header >.row:hover .snb_area {
  background: rgba(7, 28, 48, 0.9);
  height: 200px;
  display: flex;
}

#header:hover {
  background-color: rgba(7, 28, 48, 0.9) !important;
}

#header >.row .logo-wrap {
  width: 100%;
}

#header.scrolled {
  background-color: rgba(7, 28, 48, 0.9) !important; /* 스크롤 시 배경색 변경 */
}

.logo {
  width: 100px;
}

.logo > a {
  width: 200px !important;
  height: initial !important;
}

.logo > a > img {
  width: 100%;
  height: 100%;
}

#header .login {
  width: 120px;
  white-space: nowrap;
  cursor: pointer;
  text-align: center;
  font-size: 15px;
}

#header .login > a {
  color: white;
  transition: ease-in-out 0.1s;
}

#header .login a:hover {
  color: #86f8ff !important;
}

.snb_area {
  height: 0;
  overflow: hidden;
  position: absolute;
  top: 100px;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  transition: ease-in-out 0.3s;
  z-index: 1;
}
.snb_area .snb_bg {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  width: 1200px;
  padding: 20px 0;
}
.snb_area .snb_bg ul:last-child {
  width: 100px;
}
.snb_area .snb_bg .row {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.snb_area.navi {
  height: 130px;
}

@keyframes slider {
  from {
    height: 0;
  }
  to {
    height: 150px;
  }
}

.snb_area h2 {
  width: 200px;
}

.snb_area ul {
  width: 150px;
}

.snb_area .snb_bg ul li{
  text-align: center;
  padding: 5px 0;
  white-space: nowrap;

  color: #ffffff;
  font-size: 1.6rem;
}

.snb_area ul > li> a{
  margin-top: 7px;
  color: #ffffff;
  transition: all 0.2s;
  font-size: 1.6rem;
}

.snb_area ul > li> a:hover {
  color: #86f8ff;
}

.menu_wrap {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
}


.mobile-btn {
  display: none;
}

.mobile-btn span {
  color: white;
  font-size: 4rem;
  cursor: pointer;
}

.close-btn {
  color: white;
  font-size: 4rem;
  cursor: pointer;
}

.mobile-wrap {
  display: flex;
  justify-content: flex-end;
  display: none;
  z-index: 100;
}

.side_menu_wrap {
  position: fixed;
  /* width: 100vw; */
  /* height: 100vh; */
  top: 0px;
  right: -1000vw;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 3;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.side_menu_wrap .side_menu {
  width: 70%;
  height: 600vh;
  background-color: rgba(0, 0, 0, 0.9);
  padding: 150px 20px 20px 60px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  row-gap: 30px;
}

.side_menu_wrap.show {
  width: 100vw;
  height: 100vh;
  right: 0;
  transition: all ease-in-out 0.3s;
}

.side_menu_wrap.show .side_menu {
  transition: all ease-in-out 5s;
 }

.side_menu_wrap .side_menu .mobile-btn {
  position: absolute;
  top: 30px;
  right: 10px;
}

.side_menu .m-login {
  margin-bottom: 20px;
  transition: ease-in-out 0.1s;
}

.side_menu .m-login a {
  color: #ffffff;
  font-size: 1.8rem;
}

.side_menu .m-login a:hover {
  color: #86f8ff;
}

.side_menu .mobile_menu {
  height: 30px;
  color: white;
  cursor: pointer;
  width: 100%;
  border: none;
  text-align: left;
  outline: none;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  transition: ease-in-out all 1s;
  font-size: 2rem;
  margin-right: 20px !important;
}

.mobile_menu:after{
  content: '\002B';
  color: white;
  font-weight: bold;
  margin-left: 5px;
}
.mobile_menu.active:after {
  content: "\2212";
}

.mobile_menu:hover {
  color: #86f8ff;
}

.mobile_sub_wrap {
  overflow: hidden;
  font-size: 1.7rem;
  width: 100%;
  transition: ease-in-out all 1s;
  padding: 0 20px;
  display: flex;
  flex-direction: column;
  row-gap: 15px;
}

.mobile_sub_wrap.active {
  transition: ease-in-out all 1s;

}

.mobile_sub_wrap > a {
  cursor: pointer;
  transition: all 0.2s ease-out;
  color: #ffffff;
}


.mobile_sub_wrap > a:hover {
  color: #86f8ff;
}

/*반응형*/

@media (max-width: 1300px) {
  #header {
    padding: 10px;
  }
  #header >.row {
    margin-right: 0;
  }
}

@media (max-width: 1200px) {
  #header >.row .logo-wrap {
    left: 30px;
  }
  .snb_area .snb_bg {
    margin-right: 20px;
  }
}

@media (max-width: 1024px) {
  #header >.row {
    margin-right: 30px;
  }
  .snb_area .snb_bg {
    margin-right: 50px;
  }
}

@media (max-width: 960px)  {

  html, body {
    /*overflow-x: hidden;*/
  }
  #header .menu_wrap {
    display: none !important;
  }

  .mobile-btn {
    display: flex;
  }

  .mobile-wrap {
    display: flex !important;
    margin-right: 20px;
  }
}

@media (max-width: 600px) {
  #header .row {}
  .side_menu {
    width: 80%;
  }
}

@media (max-width: 540px) {
  #header {
    /*padding: 20px;*/
  }
}


</style>