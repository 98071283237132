<template>
  <div v-if="isPopupOpen" class="popup popup-page fix flex__c__m" @click="closePopupOutside">    
    <div class="popup-content flex__c__m rel" :class="{'smooth': isTransitioning }"> 
      <div class="w100">
        <p class="t1 tit">신청가능 세부전공</p>
        <p class="t2 mgt20">총 <strong class="cl1">2</strong>개의 세부전공의 이수조건을 충족하였습니다.</p> 
        <div class="scroll-box">
          <div class="box mgt10">
            <p class="t3">1개의 세부전공을 선택하여 디지털배지를 발급신청 할 수 있습니다.</p>
            <div class="mgt20">
              <label class="rad"><input type="radio" name="data1" checked><i></i><span>차세대통신 초급</span></label>
              <label class="rad"><input type="radio" name="data1"><i></i><span>차세대통신 고급</span></label>
            </div>          
          </div>
        </div>      
      </div>   
      <div class="pop-btn tc">
        <button @click="closePopup" type="button" class="ty2">확인</button>
      </div>
      <button @click="closePopup" type="button" class="pop-clz abs">
        <svg  viewBox="0 0 24 24">
          <line x1="18" y1="6" x2="6" y2="18"></line>
          <line x1="6" y1="6" x2="18" y2="18"></line>
        </svg>
      </button>
    </div>
  </div>
</template>

<script>
export default {
  props: ['popupId'],
  computed: {
    isPopupOpen() {
      return this.$store.getters.getPopupById(this.popupId) !== undefined;
    },
  },
  data() {
    return {
      isTransitioning: false,
    };
  },
  methods: {
    closePopup() {
      this.isTransitioning = true;
      setTimeout(() => {
        this.isTransitioning = false;
        this.$store.dispatch('closePopup', this.popupId);
      }, 200);
    },
    closePopupOutside(event) {
      if (event.target.classList.contains('popup')) {
        this.closePopup();
      }
    }, 
  }
};
</script>

<style lang="scss" scoped>
.lms {
  .popup {
    .popup-content {
      max-width:52rem !important;
      .scroll-box {
        max-height:40rem !important;
        .box {
          padding:3rem 2.6rem;
          color: $wh;
          background:#5d9aff;
          border-radius: 1.5rem;
          .t3 {
            font-size: 1.5rem;
          }
          .rad {
            $cw : 2rem;
            display: block;
            position: relative;
            margin-top:.5rem;
            font-size:2rem;      
            line-height: calc($cw + 1rem);
            cursor:pointer;
            i {
              display: block;
              position: absolute;
              width: $cw;
              height: $cw;
              top:2.1rem;
              left:2rem;
              border: .1rem solid $wh;
              border-radius: 50%;
              z-index: 2;
            }
            input {
              display: block;
              width: 100%;
              height: 0;            
            }
            span {
              display: block;
              padding:1.6rem 2rem 1.6rem 5rem;
              border-radius: 1rem;
              @include transition();
            }
            input:checked + i, &:hover input + i {
              background: $wh;
            }   
            input:checked + i::after, &:hover input + i::after{
              $pos : calc(50% - .5rem);
              content: "";
              display: block;
              position: absolute;
              width: 1rem;
              height: 1rem;
              top:$pos;
              left:$pos;
              background: $tm4;
              border-radius:50%;
            }
            input:checked + i + span, &:hover input + i + span {
              background: #4483ed;
            }
          } 
        }
      }      
    }        
  }
}
</style>